@import '~antd/dist/antd.less';


#header {
  position: fixed;
  z-index: 3; 
  width: 100%;
  padding: 0px 7vw;
}
#main-logo {
  display:inline-block;
  margin: -2px 30px 0px 0px;
  font-size: 25px;
}
#main-logo > img {
  width: 35px;
  height: 40px;
}

.login-form {
  width: 90%;
  margin: 0px auto;
}
.login-form-button {
  width: 100%;
}
.ant-card-body {
  padding: 10px;
}
.ant-list-split .ant-list-item {
  background-color: #fff;
}

.cannot-be-found {
  position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
}
#landing-section {
  background: #001529;
  height: 80vh;
  padding: 7vw;
}
#landing-section > div > h1, #landing-section > div > h5, #landing-section > div > p {
  color: white;
}
.text-color-green {
  color: #33bcb7;
}

#collection-show, #collection-list-container, #wallet-dashboard, #purchased-content-page, #scheduled-licenses-page{
  padding: 0px 7vw;
}

.ant-page-header, .collection-list-item  {
  box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
}

.ant-page-header.import-collection-modal {
  box-shadow: none;
  padding: 0;
}

#collection-show > .ant-page-header {
  box-shadow: 3px -8px 25px rgba(0,0,0,0.2);
}

#collection-show > .module-list {
  box-shadow: 3px 3px 10px rgba(0,0,0,0.2);
}



.clearfix::after {
  content: "";
  clear: both;
  display: table;
}


#video-content-wrapper {
  position: relative;
  max-height: calc(100vh - 64px - 98px - 70px);
  background-color: black;
  overflow: none;
}

#navigation-links{
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
#navigation-links > .ant-space-item {
  height: 64px;
}

.module-video-list {
  position: relative;
  overflow: auto;
  /* bottom padding to account for shadow cutoff on card hover */
  padding-bottom: 10px;
}


.loading-indicator {
  margin: auto;
  width: 50%;
}

.transcript-segment {
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  line-height: 25px;
} 

.transcript-segment:hover, #highlighted-transcript-segment:hover{
  cursor: pointer;
  //color: #43f2ec;
  color: rgb(89, 251, 170);
}

.visible-transcript-sider {
  padding: 0px 0px;
  height: calc(100vh - 64px - 98px - 70px);
  overflow: hidden;
  background: #001529;
}

#highlighted-transcript-segment {
  color: white;
}

.greyed-card {
  background-color: grey;
  opacity: 0.5;
}

#stripe-badge {
  width: 110px;
}

.empty-transcript-message {
  color: white;
}

.scheduled-license-col > .ant-list > .ant-list-header {
  background: #001529d7;
  color: white;
}
#transcript-tab-container > .ant-tabs-nav{
  margin: 0 15px;
}
#transcript-tab-container > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list >.ant-tabs-tab {
  margin: 0 16px 0 0;
}

#transcript-tab-container > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list >.ant-tabs-tab > .ant-tabs-tab-btn > .ant-badge{ 
  color: white;
}


#video-page-layout {
  background: #001529;
  height: calc(100vh - 64px - 98px - 70px);
}

#video-page-menu {
  background: #001529; 
  overflow: auto; 
  height: calc(100vh - 64px - 98px - 70px);
}

.stripe-card-input {
  background: white;
  padding: 10px 15px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  margin-bottom: 5px;
}
.stripe-card-input:focus{
  box-shadow: 0 4px 8px 0 rgba(6, 143, 255, 0.2);
}

.stripe-card-error {
  box-shadow: 0 4px 8px 0 rgba(255, 0, 0, 0.63);
}

.card-error{
  font-size: 15px;
  font-weight: 500;
  color: rgb(255, 79, 79);
  height: 25px;
}

#top-up-form, #payment-form {
  padding: 25px;
}

#stripe-container {
  background: #ffffff;
  border-radius: 5px;
  padding: 20px 0px 0px 0px;
  margin-top: 5px;
}

#stripe-pay-now {
  width: 100%;
  background: #556CD6;
  border-color: #556CD6;
}

.quiz-option {
  margin: 10px 0px;
  padding: 25px;
  border: 1px solid rgb(219, 219, 219);
  transition: all 0.2s linear;
  border-radius: 5px;
  display: inline-block;
  width: 100%;
}

.quiz-option:hover {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  cursor: pointer;
}
.quiz-option > span > pre {
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.selected-quiz-option {
  border: 1px solid #33bcb7;
}

.correct-quiz-option{
  border: 1px solid #64ca6d;
}

.correct-answer-ribbon {
  background-color: #64ca6d;
}

.correct-quiz-option:hover, .wrong-quiz-option:hover, .non-clickable-option:hover {
  box-shadow: none;
  cursor: default;
}

.wrong-quiz-option {
  border: 1px solid rgb(214, 113, 99);
}

.not-full-correct-quiz-option {
  border: 1px solid rgb(211, 190, 96);
}

.question-legend-dot-green {
  border: 2px solid #52c41a;
  background-color: white;
  border-radius: 100px;
  width: 10px;
  height: 10px;
}

.quiz-module-purchase-headers {
  width: 132.5px;
  text-align: center;
  color: white;
}

.quiz-module-purchase-headers-text{
  color: white;
}

.page-header-without-bottom-padding {
  padding-bottom: 0px;
}

.quiz-module-purchase-headers-container >.ant-card-body{
  padding: 5px 0px 5px 16px;
}

.quiz-module-purchase-headers-container {
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  background-color: #001529;
  padding: 5px 24px;
}
// TODO -- FIX MERGE

#login-register-btn-wrapper {
  float: right;
}

#header {
  position: fixed;
  z-index: 3; 
  width: 100%;
  padding: 0px 7vw;
}
#header > .logo {
  float: left;
  margin: -2px 30px 0px 0px;
  font-size: 25px;
}
#header > .logo > img {
  width: 35px;
  height: 40px;
}

.login-form {
  width: 90%;
  margin: 0px auto;
}
.login-form-button {
  width: 100%;
}
.ant-card-body {
  padding: 10px;
}
.ant-list-split .ant-list-item {
  background-color: #fff;
}

.cannot-be-found {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
#landing-section {
  background: #001529;
  height: 80vh;
  padding: 7vw;
}
#landing-section > div > h1, #landing-section > div > h5, #landing-section > div > p {
  color: white;
}
.text-color-green {
  color: #33bcb7;
}

#collection-show, #collection-list, #wallet-dashboard, 
#purchased-content-page, #scheduled-licenses-page, #module-pool-container, #creator-panel,
#collection-creator-container, #module-creator-container, #creator-application-form-page{
  padding: 0px 7vw;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

#video-wrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  padding-top: 25px;
  height: calc(100% - 48px);
  background-color: black;
}
#video-content-wrapper {
  max-height: 100vh;
}
#video-wrapper iframe, #player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.module-video-list {
  position: relative;
  overflow: auto;
  /* bottom padding to account for shadow cutoff on card hover */
  padding-bottom: 10px;
}


.transcript-segment {
  color: rgba(255, 255, 255, 0.65);
  font-size: 14px;
  line-height: 25px;
} 

.transcript-segment:hover, #highlighted-transcript-segment:hover{
  cursor: pointer;
  //color: #43f2ec;
  color: rgb(89, 251, 170);
}

.visible-transcript-sider {
  padding: 0px 0px;
  min-height: 73vh;
  max-height: 80vh;
  overflow: hidden;
  background: #001529;
}

#transcript-tab {
  color: white;
  background: white;
}
#highlighted-transcript-segment {
  color: white;
}

.greyed-card {
  background-color: grey;
  opacity: 0.5;
}

#stripe-badge {
  width: 110px;
}

.empty-transcript-message {
  color: white;
}

.scheduled-license-col > .ant-list > .ant-list-header {
  background: #001529d7;
  color: white;
}
#transcript-tab-container > .ant-tabs-nav{
  margin: 0 15px;
}
#transcript-tab-container > .ant-tabs-nav > .ant-tabs-nav-wrap > .ant-tabs-nav-list >.ant-tabs-tab {
  color: white;
}

#transcript-tab-content {
  height: calc(80vh - 46px);
  overflow: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  text-align: left;
  letter-spacing: 0.5px;
  position: relative;
  scroll-behavior: smooth;
  background: #001529d7;
  padding: 10px 15px;
  margin: 5px 0px;
}

#transcript-tab-content.quiz-question-creator-transcript{
  height: calc(50vh);
}
#transcript-tab-content::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

#video-page-layout {
  background: #001529;
  height: 80vh;
}

#video-page-menu {
  background: #001529; 
  overflow: auto; 
  min-height: 73vh;
  max-height: 80vh;
}


#module-pool-list {
  margin-top: 72px;
}

#module-pool-page-header, #collection-creator-header, #module-creator-header {
  position: fixed;
  z-index: 1; 
  width: calc(100% - 14vw);
  box-shadow: 0px 0px 3px rgba(24, 24, 24, 0.37);
}

.module-pool-video-card {
  max-width: 480px;
}

.module-pool-video-card > .ant-card-actions > li:last-child {
  color: #33bcb7;
}


.module-pool-video-player > .ant-modal-content, .ant-modal-body {
  padding: 0px;
}

.user-created-modules-list-item {
  padding: 20px;
}

#creator-panel-tabs > .ant-tabs-nav{
  margin-bottom: 0px;
}

#add-video-by-playlist-or-video-url-form, #create-scheduled-license-form{
  padding: 25px;
}

.created-module-title {
  display: flex; 
  justify-content: space-between;
}

.module-pool-video-player-modal-content{
  background-color: #000;
  display: flex;
  flex: auto;
  min-height: 0;
  align-items: center;
}

#transcript-in-modal {
  width: 40%;
  display: inline-block;
  height: 710px;
  overflow: auto;
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
  scrollbar-width: none;  /* Firefox */
  text-align: left;
  letter-spacing: 0.5px;
  position: relative;
  scroll-behavior: smooth;
  padding: 10px 15px;

  background-color: #001529;
}
#transcript-in-modal::-webkit-scrollbar { 
  display: none;  /* Safari and Chrome */
}

.module-pool-video-player > .ant-modal-content > .ant-modal-footer {
  text-align: left;
}

.player-in-modal {
  width: 60%;
  display: inline-block;
  background-color: black;
  height: 100%;
}

.player-in-modal > #vimeo-player > iframe {
  width: 100%;
}

#new-collection-form, #new-module-form {
  width: 65%;
  display: inline-block;
  padding: 15px;
}

#new-collection-form-container, #new-module-form-container{
  margin-top: 72px;
  vertical-align: top;
}

#imported-videos-container {
  margin-left: 3%;
  width: 27%;
  display: inline-block;
  height: 75vh; 
  max-height: 75vh;
  position: fixed;
}

#imported-videos-container > .ant-card-head {
  background-color: #001529;
  color: white;
  min-height: 40px;
}

#imported-videos-container > .ant-card-head > .ant-card-head-wrapper > .ant-card-head-title {
  padding: 12px 0px;
}

#imported-videos-container > .ant-card-body {
  padding: 0px;
}
#imported-videos-scroll-container {
  height: calc(75vh - 50px - 62px);
  overflow-x: hidden;
  overflow-y: auto;
}

#imported-video-cards-wrapper {
  height: 100%;
  padding: 24px;
}

.new-collection-form-module-card{
  padding: 15px;
  //background-color: #fafafa;
}


.imported-video-card > .ant-card-cover {
  height: calc(100% - 42px);
}

.imported-video-card > .ant-card-body {
  max-height: 42px;
}
.imported-video-card:hover {
  cursor: pointer;
}

.video-selected {
  border: 5px solid #33bcb7 !important;
}

.highlighted-video-list {
  border: 1px dashed #33bcb7;
  cursor: pointer;
}

.highlighted-video-list-blur {
  background-color: #fff;
  -webkit-filter: blur(6px);
  -moz-filter: blur(6px);
  -o-filter: blur(6px);
  -ms-filter: blur(6px);
  filter: blur(6px);
}
.videos-in-module-list {
  position: relative;
  padding: 5px 5px 0px 5px;
  height: 400px;
  background-color: rgb(230, 230, 230);
}

.videos-in-module-list > .ant-card-body {
  overflow-x: hidden;
}

.click-to-add-selected-video-message {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(255, 255, 255, 0.37);
}

.click-to-add-selected-video-message > .ant-typography{
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
  color:white;
  margin: 0;
}

.hidden {
  display: none;
}

.video-card-in-module-collection-creator {
  margin-bottom: 0px;
}

.selected-collection-page-header-in-collection-creator {
  padding: 0px;
}

.loading-indicator-container {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}



.upload-cover-image {
  width: 100%;
  height: auto;
  position: relative;
}

.upload-cover-image > .ant-upload {
  width: 100%;
  height: auto;
}

.upload-cover-image-loading{
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  font-size: 30px;
}
.upload-cover-image-hover-div {
  width: 100%; 
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.336); 
  opacity: 0;
  transition: opacity 0.2s;
}
.upload-cover-image-hover-div:hover {
  opacity: 0.8;
}
.upload-cover-image-hover-div > .ant-typography {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;
}

.modules-component > .ant-card-body {
  padding: 0;
}
.modules-component-video-list-container {
  position: relative;
}

.modules-component-video-list-container > .ant-card-body {
  overflow-x: hidden;
}

.module-descriptions-card {
  height: 100%;
}

.module-descriptions-card > .ant-card-body {
  height: calc(100% - 47px);
}

.modules-component-video-card {
  width: 300px;
  height: 200px;
}

.import-content-from-modal > .ant-modal-content > .ant-modal-body {
  padding: 0px;
}

.centered-on-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}

#creator-application-form {
  margin: 0 auto;
}

#existing-questions-container {
  overflow-x: auto;
  white-space: nowrap;
  width: 100%;
}

.module-form-collapse-panel > .ant-collapse-content > .ant-collapse-content-box{
  padding: 0;
}
.white-color-text {
  color: white;
}

.disable-highlighting {
  /* https://stackoverflow.com/questions/826782/how-to-disable-text-selection-highlighting */
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none;/* Non-prefixed version, currently
            supported by Chrome, Edge, Opera and Firefox */
}

.schedule-accesses-container{
  height: 200px;
  overflow-y: auto;
  padding: 15px;
  border: 1px solid black;
}

.hugo {
  position:fixed; top:0; left:0; bottom:0; right:0; width:100%; height:100%; border:none; margin:0; padding:0; overflow:hidden; z-index:999999;
}

.hoverable {
  cursor: pointer;
}

.form-error-list {
  list-style-type: none; /* Remove bullets */
  padding: 0; /* Remove padding */
  margin: 0; /* Remove margins */
}

.bold-error-list-item {
  font-weight: bold;
}

.normal-error-list-item {
  font-weight: normal;
}

.rich-editor {
  border: 1px solid #ddd;
}

.rich-editor-input {
  padding: 15px;
}


.rich-editor-buttons {
  border-bottom: 1px solid #ddd;
}

.selected-rich-editor-button {
  background: rgba(0, 0, 0, 0.11);
}

.selected-rich-editor-button:focus {
  background: rgba(0, 0, 0, 0.11);
}
.selected-rich-editor-button:hover {
  background: rgba(0, 0, 0, 0.11);
}

.rich-editor-blockquote{
  border-left: 5px solid #eee;
  color: #666;
  font-family: 'Hoefler Text', 'Georgia', serif;
  font-style: italic;
  margin: 16px 0;
  padding: 10px 20px;
}

.rich-editor-hide-placeholder {
  display: none;
}

.rich-editor-code-block {
  padding: 2px 5px;
  color: rgba(0, 0, 0, 0.473);
  background-color: rgba(245, 246, 247, 0.904);
  margin-bottom: 0px;
}

.question-text-image-upload-div{
  border: 1px solid #ddd;
  padding: 10px 10px 0px 10px;
}

.public-DraftStyleDefault-pre {
  background-color: rgba(242, 242, 242, 0.979);
  padding: 10px;
}

.quizquestion-carousel-width {
  width: 100%;
  margin: 0 auto;
}

.quizquestion-drawer-content-width {
  width: 90%;
  margin: 0 auto;
}

.standard-modal-content {
  padding: 25px;
}

.quiz-questions-direct-buttons {
  width: 100%; 
  background-color: black;
  height: 48px;
  padding: 10px;
}

.quiz-questions-direct-buttons-scrollable-container {
  display: inline-block; 
  margin-left: 10px; 
  width: 300px;
  position: relative;
  overflow: auto;
}

.quiz-questions-direct-buttons-container {
  width: 100%;
}

#video-page-extra-content-layout {
  background-color: #EEEEEE;
  min-height: 100vh;
}

#q-and-a-section {
  margin-left: 15.625vw;
  width: calc(100% - 15.625vw - 450px);
  padding: 64px 25px 25px 25px;
}

#q-n-a-instructor-square{
  background-color: #868CBE;
  width: 15px;
  height: 15px;
  border-radius: 2px;
  display: inline-block;
  margin-top: 5px;
}
.instructor-color {
  color: #868CBE;
}

#q-n-a-header {
  display: flex;
  justify-content: space-between;
}

.qna-reply-form-container {
  max-height: 0px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}


.qna-reply-editor {
  background-color: white;
}

.qna-reply-username {
  color: rgba(0, 0, 0, 0.45);
  font-size: 12px;
}
.qna-replies{
  max-height: 0px;
  overflow: hidden;
  transition: all 0.2s ease-in-out;
}

.visible-qna-replies {
  display: block;
}

.qna-sub-heading {
  font-size: 12px; 
  color: #595959;
}

.spin-container {
  margin: 20px 0;
  width: 100%;
  margin-bottom: 20px;
  padding: 30px 50px;
  text-align: center;
  border-radius: 4px;
}

.highlight-new-comment {
  -webkit-animation: highlightcomment linear 1s 1;
	animation: highlightcomment 0.2s linear 0.5s 1;
}

@keyframes highlightcomment {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes highlightcomment {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.1;
  }
  100% {
    opacity: 1;
  }
}

#search-module-video-list{
  overflow: auto;
  position: relative; 
  scroll-behavior: smooth;
}

#search-module-video-list::-webkit-scrollbar {
  height: 0px;
  background: transparent; /* make scrollbar transparent */
}

.search-module-video-card-container {
  padding: 15px;
}

.search-module-video-card-container:hover {
  cursor: pointer;
}

.search-module-video-card-container-selected {
  background-color: #001529;
}

.collection-search-keyword-highlight{
  color: #33bcb7;
  background-color: transparent !important;
  padding: 0;
}

.collection-search-results-video-container{
  background-color: #1a5c5a;
  position: relative;
}

.collection-search-video-transcripts-container {
  background-color: #001529;
  padding: 20px;
}

.collection-search-keyword-unhighlight{
  color: white;
}

.collection-search-video-transcript-segment {
  display: flex;
  color: white;
  transition: all 0.1s ease-in-out;
  cursor: pointer;
  opacity: 0.7;
  padding: 5px 0px;
}

.collection-search-video-transcript-segment:hover {
  opacity: 1;
}

.collection-search-input-container {
  padding: 10px 25px;
  background-color: #1a5c5a;
  z-index: 3;
  border-bottom: solid 1px white;
}

.timestamp-component {
  color: rgb(61, 156, 200);
  cursor: pointer;
  transition: all 0.1s ease-in;
}

.timestamp-component:hover {
  color:rgb(123, 192, 224)
}

.qna-anonymous-spoiler-tag {
  position: relative;
  background-color: rgba(47, 47, 47, 0.548);
  color: transparent;
  z-index: 2;
  padding: 1px 2px;
  opacity: 1;
  border-radius: 3px;
  transition: all 0.2s ease-in;
}

.qna-anonymous-spoiler-tag:hover {
  color: black;
  background-color: transparent;
}

.qna-anonymous-hover-to-reveal {
  color: white;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  z-index: 3;
  transition: all 0.2s ease-in;
}

.qna-anonymous-hover-to-reveal:hover {
  z-index: 0;
  opacity: 0;
}

@primary-color: #33bcb7;@badge-color: #722ed1;